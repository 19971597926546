import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import PlainHeader from "components/ui/base/PlainHeader";
import Customers from "components/ui/extended/Customers";
import BentoBoxCustomerServiceSolutions from "components/ui/extended/BentoBoxCustomerServiceSolutions";
import CustomerSupportFeatures from "components/ui/extended/CustomerSupportFeatures";
import TestimonialWithImageFrancesca from "components/ui/extended/TestimonialWithImageFrancesca";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import Awards from "components/ui/extended/Awards";

interface AICustomerServicePageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    beforeImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    afterImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: AICustomerServicePageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      location={location}
      layoutSettings={{ withCta: false, withMenu: false, withFooter: false }}
    >
      <SEO
        title={t("pages.ai.seo.title")}
        description={t("pages.ai.seo.description")}
        image={{
          relativePath: "meta/customerly-ai.jpg",
          alt: "Live Chat with Superpowers",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.ai.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.ai.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(`pages.ai.faq.${index}.question`),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(`pages.ai.faq.${index}.answer`),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeader
        title={t("pages.customer-service-solution.title")}
        description={t("pages.customer-service-solution.description")}
        titleMaxWidth={1260}
        descriptionMaxWidth={900}
        ctaRegister={{ show: true }}
        ctaGetDemo={{
          show: true,
        }}
      />

      <BentoBoxCustomerServiceSolutions />

      <Customers />

      <CustomerSupportFeatures />

      <TestimonialWithImageFrancesca />

      <RegisterForCustomerService />

      <Awards />
    </GlobalWrapper>
  );
};

export const AICustomerServicePagePropsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/customerly-ai.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    beforeImage: file(
      relativePath: { eq: "pages/ai/before.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    afterImage: file(
      relativePath: { eq: "pages/ai/after.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
